import React from "react";
import styled from "styled-components"
import Youtube from '../../assets/svg/youtube.svg';
import Facebook from '../../assets/svg/facebook-logo.svg';
import PromotionLayout from "../../container/PromotionLayout"
import { graphql, Link as GatsbyLink, useStaticQuery } from "gatsby"

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Link = styled.a`
  width: 100%;
  height: 50px;
  background-color: var(--fourth-color);
  border: 3px var(--fourth-color) solid;
  border-radius: 5px;
  line-height: 46px;
  text-indent: 30px;
  font-weight: bold;
  font-size: 25px;
  cursor: pointer;
  transition: background-color .3s;
  margin-bottom: 30px;
  color: black;
  text-decoration: none;
  
  @media screen and (max-width: 768px) {
    font-size: 15px;
    text-indent: 15px;
  }
`

const DescriptionContainer = styled.div`  
  padding: 30px 20px 100px;
  
  @media screen and (max-width: 768px) {
    padding: 20px 0 50px;
  }
`

const Description = styled.div`
  padding-left: 10px;
  border-left: 3px solid var(--second-color);
`

function LinkPage () {

  const {
    allLinkItemJson: { edges: linkItems },
  } = useStaticQuery(graphql`
      query {
          allLinkItemJson {
              edges {
                  node {
                      name
                      link
                  }
              }
          }
      }
  `)
  return (
    <PromotionLayout title="카탈로그">
      <DescriptionContainer>
        <Description>
          동아특수건설의 자세한 기술은
          아래 카탈로그에서 만나보실 수 있습니다
        </Description>
      </DescriptionContainer>
      <LinkContainer>
        {linkItems.map(({ node }) => (
          <Link href={node.link} download>
            {node.name}
          </Link>
        ))
        }
      </LinkContainer>
    </PromotionLayout>
  )
}

export default LinkPage;